/* Component styles */
.image-container {
    position: relative;
}

.image-container--center {
    display: flex;
    justify-content: center;
}

.image-container--fade {
    &:after {
        content: '';
        position: absolute;
        left: -18rem;
        bottom: -41rem;
        height: rem(300px);
        width: 25rem;
        background-image: linear-gradient(to bottom, rgba(255,255,255,0.001) 0%, #fff 100%);
    }
}

/* Image based styles */
.image {
    display: block;
    max-width: 100%;
    height: auto;
}

.image--crop {
    display: none;

    @media (min-width: $break-sm) {
        display: block;
        position: absolute;
        width: 23rem;
        left: -18rem;
        top: -2rem;
    }

    &.image--small {
        display: block;
        position: absolute;
        width: 7rem;
        right: 0;
        top: -4rem;

        @media (min-width: $break-sm) {
            left: auto;
            top: -9rem;
        }
    }
}

// .image--products {
//     width: 100%;

//     @media (min-width: $break-sm) {
//         margin-top: -7rem;
//     }
// }

.image--cornflower-bg {
    position: absolute;

    @media (min-width: $break-sm) {
        width: 45rem;
        max-width: 45rem;
        left: -12rem;
    }
}

.image--cornflower-bottle {
    height: 25rem;

    @media (min-width: $break-sm) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}

.image--silver-bottle {
    width: 100%;

    @media (min-width: $break-sm) {
        position: absolute;
        transform: translateY(-50%) scale(1.3);
    }
}

.image--silver-bottle-2 {
    width: 100%;

    @media (min-width: $break-sm) {
        position: absolute;
        top: -8rem;
        left: -1rem;
        transform: scale(1.1);
    }
}

.image--silver-logo {
    width: 12rem;
}

.image--vodka-bottles {
    width: 7rem;
}

.image--horn-1 {
    @media (min-width: $break-sm) {
        position: absolute;
        width: 18rem;
        top: -14rem;
    }
}

.image--grain-sign {
    @media (min-width: $break-sm) {
        position: absolute;
        top: -6rem;
        right: -14rem;
    }
}

#grain {
    z-index: 49;
}

.image--background {
    position: absolute;
    top: rem(30px);
    left: -10vw;
    right: -10vw;
    height: 45rem;
    max-width: none;
    transform: rotate(-4deg);

    @media (min-width: $break-sm) {
        width: 120vw;
    }
}
