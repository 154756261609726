html {
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    position: relative;
    display: block;
    background: $white;
    overflow-x: hidden;

    &.scroll-disabled {
        overflow: hidden;
        position: fixed;
        width: 100%;
    }
}

.container {
    width: 100%;
    max-width: $container-width;
    padding-left: $grid-gutter * 0.5;
    padding-right: $grid-gutter * 0.5;
    position: relative;
    margin: 0 auto;
}

.container--small {
    max-width: rem(600px);
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}
