.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: $z-index-always-top;
}

.modal__container {
    position: relative;
    min-height: 100vh;
    z-index: $z-index-3;
}

.modal__content {
    position: relative;
    background: $white;
    margin: 0 auto;
    padding: rem(15px);
    width: 100%;

    @media(min-width: $break-sm) {
        padding: rem(50px);
        max-width: rem(1200px);
    }
}

.modal__close {
    display: flex;
    position: absolute;
    top: 0;
    right: rem(15px);
    background: $brown;
    border: none;
    color: $white;
    padding: rem(8px);
    margin: 0;
    line-height: 1;
    cursor: pointer;

    @media(min-width: $break-sm) {
        font-size: 2rem;
        right: rem(50px);
    }

    @media(min-width: $break-md) {
        font-size: 3rem;
    }
}
