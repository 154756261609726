.content--center {
    text-align: center;
}

.content--right {
    @media (min-width: $break-sm) {
        text-align: right;
    }
}

.content--dark {
    color: #000;
}

.content--white {
    color: white;
}

.content--small {
    p {
        font-size: rem(13px);
    }
}

.content__paragraph {
    .content--condensed & {
        margin-top: .2rem;
        margin-bottom: .2rem;

        &:last-child {
            margin-top: 1rem;
        }
    }
}