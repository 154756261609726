@charset 'utf-8';
@import 'base/_all';
@import 'components/_all';

/* overlay */
.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $overlay-color;
    opacity: $overlay-opacity;
    z-index: $overlay-zindex;
}

iframe {
    background: white;
}

.vodka--shift {
    margin-top: 3rem;
}

.fixed {
    z-index: $z-index-5;
    position: fixed;
}

.fixed--top {
    top: 0;
}

.fixed--bottom {
    bottom: 0;
}

.fixed--left {
    left: 0;

    @media (min-width: $container-width) {
        left: calc(50% - #{$container-width / 2} + #{$grid-gutter / 2});
    }
}

.fixed--right {
    right: 0;

    @media (min-width: $container-width) {
        right: calc(50% - #{$container-width / 2} + #{$grid-gutter / 2});
    }
}

.fixed--zindex {
    z-index: $z-index-1;
}

.fixed--padding {
    padding-left: $grid-gutter / 2;
    padding-right: $grid-gutter / 2;
}

.ratio-16-9 {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
}

.no-margin {
    margin: 0;
}
