.section {
    position: relative;
    width: 100%;
}

.section--landing {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.section--padding,
.section--blue {
    padding: 5rem 0;
}

.section--fullscreen {
    min-height: 100vh;
    display: flex;
    align-items: center;
}

.section--contact {
    height: 100vh;
    display: flex;
    align-items: center;
}

.section--image {
    background: $black;

    @media (min-width: $break-sm) {
        background: transparent;
    }
}

.section__container {
    width: 100%;
    max-width: rem(880px);
    padding: 1rem $grid-gutter * 0.5;
    margin: 0 auto;

    .section--landing & {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-width: $break-md) {
            max-width: 70vw;
        }

        @media (min-width: $break-xl) {
            max-width: 50vw;
        }
    }

    .section--no-padding & {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.section__vodka {
    // max-width: rem(120px);
    max-height: 75vh;
    z-index: 2;

    @media (min-width: $break-md) {
        height: 100%;
        max-height: 80vh;
        // width: 12vw;
        // max-width: rem(250px);
    }
}

.section__background {
    padding: 5rem 0;
    position: relative;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 200%;
        height: 100%;
        background: $blue;
        transform: translateX(-25%) rotate(-4deg);

        .section--image & {
            display: none;
        }
    }
}
