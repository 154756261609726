.collapse {
    display: none;

    &.show {
        display: block;
    }
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: $collapse-transition;
}