.btn {
    border: none;
	text-transform: uppercase;
	font-weight: $semibold;
	cursor: pointer;
	padding: $button-default-padding;
    background-color: $button-default-color;
    color: $white;
    transition: .2s background-color;

    &:focus {
        outline: none;
    }

    &:hover {
        background-color: $button-default-color-hover;
    }
}

.btn--primary {
    background-color: $button-primary-color;

    &:hover {
        background-color: $button-primary-color-hover;
    }
}

.btn--secondary {
    background-color: $button-secondary-color;

    &:hover {
        background-color: $button-secondary-color-hover;
    }
}