.contact {
    color: $black;
    padding-left: 3.5rem;
}

.contact__title {
    margin-bottom: 2rem;
}

.contact__icon {
    width: 1.5rem;
    position: absolute;
    left: -2.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.contact__list {
    list-style: none;
    padding: 0;

    & > li {
        position: relative;
        margin-bottom: 1.5rem;
    }
}

.contact__link {
    color: $black;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}