// Base colors
$black: #000000;
$white: #FFFFFF;

$grey-l: #F8F8F8;
$grey: #D4D4D5;
$grey-d: #98989A;
$blue-l: #009DE2;
$blue: #002169;
$brown: #9B7A4C;
$red: #E65100;
$green: #00E676;
$yellow: #FFFF00;

// Breakpoints
// Mobile first
// Mobile 320 - 599
$mobile: 320px;

// Extra small (320px - 799px)
// Use the modifier "-xs" when defining classes.
$break-xs: 320px;

// Small (800px - 999px)
// Use the modifier "-sm" when defining classes.
$break-sm: 800px;

// Medium (1000px - 1199px)
// Use the modifier "-md" when defining classes.
$break-md: 1000px;

// Large (1200px - 1679px)
// Use the modifier "-lg" when defining classes.
$break-lg: 1200px;

// Large (1680px - ...)
// Use the modifier "-xl" when defining classes.
$break-xl: 1680px;

// Grid
$grid-columns: 12;
$grid-gutter: 1rem;

// Font
$myriad-pro: 'MyriadPro';
$massimo: 'Massimo';

$base-font: $myriad-pro;
$base-header-font: $massimo;
$base-font-size: 16px;
$base-line-height: 1.4;
$base-color: $blue;
$text-small-size: rem(12px);

$light: 300;
$regular: 400;
$semibold: 600;
$bold: 700;

// Typography;
$h1-size: rem(36px);
$h1-font: $base-header-font;
$h1-font-weight: $semibold;

$h2-size: rem(26px);
$h2-font: $base-header-font;
$h2-font-weight: $semibold;

$h3-size: rem(20px);
$h3-font: $base-header-font;
$h3-font-weight: $semibold;

$h4-size: rem($base-font-size);
$h4-font: $base-font;
$h4-font-weight: $bold;

$h5-size: rem(12px);

// Button
$button-default-font-size: 0.844em;
$button-default-padding: 0.750em 1.875em;
$button-default-color: $blue;
$button-default-color-hover: tint($blue, 20);

$button-primary-color: $brown;
$button-primary-color-hover: tint($brown, 20);

$button-secondary-color: $grey-d;
$button-secondary-color-hover: tint($grey-d, 20);

$button-small-font-size: 0.625em;

// Collapse
$collapse-transition: height .35s ease;

// Z-index
$z-index-1: 10;
$z-index-2: 20;
$z-index-3: 30;
$z-index-4: 40;
$z-index-5: 50;
$z-index-always-top: 1000;

// Overlay
$overlay-color: $blue;
$overlay-opacity: 0.91;
$overlay-zindex: $z-index-5;

// Container
$container-width: rem($break-lg);
