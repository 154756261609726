.age-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: $z-index-always-top;
}

.age-popup__container {
    position: relative;
    background: $white;
    margin: 0 auto;
    width: 100%;

    @media(min-width: $break-sm) {
        max-width: rem(400px);
    }
}

.age-popup__content {
    padding: rem(25px) rem(25px) 0 rem(25px);

    @media(min-width: $break-sm) {
        padding: rem(90px) rem(70px) 0 rem(70px);
    }
}

.age-popup__logo {
    width: rem(120px);
}
