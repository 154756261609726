.wavy-border {
    position: absolute;
	background-image: url('../images/menu-sakk.svg');
    height: 5px;
    left: 0;
    right: 0;
    bottom: -3px;
}

.wavy-border--white {
    background-image: url('../images/menu-sakk-valge.svg');
}

.wavy-border--top {
    transform: rotate(180deg);
    top: -3px;
    bottom: auto;
}

