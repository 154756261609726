@mixin gradientColor($color) {
    &:after {
        background: linear-gradient(to right, transparent 0%, rgba($color, 0.1) 100%);
    }

    &.gradient--left {
        &:after {
            background: linear-gradient(to left, transparent 0%, rgba($color, 0.1) 100%);
        }
    }
}

.gradient {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        top: 12rem;
        left: 15vw;
        background: linear-gradient(to right, transparent 0%, rgba($red, 0.1) 100%);
        width: 100%;
        height: 9rem;
        transform: translateX(1%) rotate(4deg);

        @media (min-width: $break-lg) {
            width: 70vw;
        }
    }
}

.gradient--left {
    &:after {
        background: linear-gradient(to left, transparent 0%, rgba($red, 0.1) 100%);
        left: auto;
        right: 15vw;
        transform: translateX(1%) rotate(-4deg);
    }
}

.gradient--top {
    &:after {
        top: 6rem;
    }
}

.gradient--bottom {
    &:after {
        top: 22rem;
    }
}

.gradient--blue {
    @include gradientColor($blue-l);
}

.gradient--green {
    @include gradientColor($green);
}

.gradient--yellow {
    @include gradientColor($yellow);
}
