.privacy-popup {
    width: 100%;
    background-color: $grey-l;
}

@media (min-width: $break-sm) {
    .privacy-popup {
        width: rem(360px);
    }
}

.privacy-popup__content {
    padding: rem(26px);

    p {
        margin: 0;
    }

    h2 {
        margin: 0;
    }
}

