.header {
    background: $grey-l;
    width: 100%;

    &.is-visible {
        @media (max-width: $break-sm - 1) {
            bottom: 0;
            overflow-y: scroll;
            -webkit-overflow-scrolling: touch;
            z-index: $z-index-always-top;
        }
    }

    @media (min-width: $break-sm) {
        width: rem(150px);
    }
}

.header__content {
    display: none;
    padding: rem(30px) rem(15px) rem(20px);
    text-align: center;

    .header.is-visible & {
        display: block;
    }

    @media (min-width: $break-sm) {
        display: block;
    }
}

.header__logo {
    margin-top: rem(20px);
    margin-bottom: rem(20px);
    width: rem(150px);

    @media (min-width: $break-sm) {
        width: auto;
    }
}

.header__languages {
    display: flex;
    justify-content: space-evenly;
}

.header__nav {
    margin-bottom: 2rem;
    list-style-type: none;
    padding: 0;
}

.header__nav-item {
    text-align: center;
}

.header__nav-link {
    display: block;
    padding: 0.5rem 0;
    text-decoration: none;
    color: $button-default-color;
    text-transform: uppercase;
    font-weight: $bold;

    &:hover,
    &:focus {
        color: $button-default-color-hover;
    }
}

.header__button-mobile {
    display: flex;
    position: absolute;
    top: $grid-gutter / 2;
    right: $grid-gutter / 2;
    background: transparent;
    border: none;
    padding: 1rem;
    margin: 0;
    outline: 0;

    @media (min-width: $break-sm) {
        display: none;
    }
}

.header__button-burger {
    position: relative;
    display: block;
    width: 15px;
    height: 2px;
    background: $button-default-color;
    transition: transform 0.2s ease-in-out;
}

.header__button-burger:before,
.header__button-burger:after {
    position: absolute;
    left: 0;
    background: $button-default-color;
    content: '';
    width: 20px;
    height: 2px;
    transition: transform 0.2s ease-in-out;
}

.header__button-burger:before {
    top: -6px;
}

.header__button-burger:after {
    top: 6px;
}

.header.is-visible .header__button-burger {
    background: transparent;
}

.header.is-visible .header__button-burger:before {
    transform: rotate(45deg) translate(5px, 4px);
}

.header.is-visible .header__button-burger:after {
    transform: rotate(-45deg) translate(5px, -4px);
}