@font-face {
	font-family: $massimo;
	src: url('../fonts/Massimo-Light.woff2') format('woff2'),
        url('../fonts/Massimo-Light.woff') format('woff');
	font-weight: $light;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: $massimo;
	src: url('../fonts/Massimo-Semibold.woff2') format('woff2'),
        url('../fonts/Massimo-Semibold.woff') format('woff');
	font-weight: $semibold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: $myriad-pro;
	src: url('../fonts/MyriadPro-Regular.woff2') format('woff2'),
        url('../fonts/MyriadPro-Regular.woff') format('woff');
	font-weight: $regular;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: $myriad-pro;
	src: url('../fonts/MyriadPro-Semibold.woff2') format('woff2'),
        url('../fonts/MyriadPro-Semibold.woff') format('woff');
	font-weight: $semibold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: $myriad-pro;
	src: url('../fonts/MyriadPro-Bold.woff2') format('woff2'),
        url('../fonts/MyriadPro-Bold.woff') format('woff');
	font-weight: $bold;
	font-style: normal;
	font-display: swap;
}