.cocktail {
    background: #faf7f8;
    height: 100%;
}

.cocktail__content {
    padding: .5rem 2rem 2rem;
}

.cocktail__ingredients {
    list-style-type: none;
    padding: 0;
}

.cocktail__content--center {
    @media (min-width: $break-sm) {
        text-align: center;
    }
}