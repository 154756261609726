.separator {
    margin: 0 0 1.5rem 0;
    border: none;
}

.separator--small {
    margin-bottom: .75rem;
}

.separator--large {
    margin-bottom: 3rem;
}

.separator--huge {
    margin-bottom: 6rem;
}