/* Globals */
html {
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-family: $base-font;
    font-weight: $regular;
    color: $base-color;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Headings, paragraph, link */
h1,
.h1 {
    font-family: $h1-font;
    font-size: $h1-size;
    font-weight: $h1-font-weight;
    text-transform: uppercase;
}

h2,
.h2 {
    font-family: $h2-font;
    font-size: $h2-size;
    font-weight: $h2-font-weight;
    text-transform: uppercase;
}

h3,
.h3 {
    font-family: $h3-font;
    font-size: $h3-size;
    text-transform: uppercase;
}

h4,
.h4 {
    font-family: $h4-font;
    font-size: $h4-size;
}

h5,
.h5 {
    font-size: $h5-size;
}

a {
    color: $blue;
    font-weight: $semibold;
    text-decoration: underline;
}

/* Utilities */
.text-grey {
    color: $grey;
}

.text-dark {
    color: $black;
}

.text-small {
    font-size: $text-small-size;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}
