.languages {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.languages__item {
    &:not(:first-child) {
        margin-left: 0.75rem;
    }
}

.languages__link {
    color: $button-secondary-color;
    text-decoration: none;
    text-transform: uppercase;
    font-size: $text-small-size;
    font-weight: $bold;

    &:hover,
    &:focus {
        color: $button-secondary-color-hover;
    }

    &.is-active {
        color: $button-primary-color;

        &:hover,
        &:focus {
            color: $button-primary-color-hover;
        }
    }
}